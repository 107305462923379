<template>
  <div>
    <vs-button color="primary" @click="backtoUser()">Back</vs-button>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="8">
        <vs-card>
          <div slot="header">
            <h3>Edit User</h3>
          </div>
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>First Name</h6>
            </vs-col>
            <vs-col type="flex" vs-w="6" v-if="this.update_first_name !== true">
              <!-- <h6>{{ name }}</h6> -->
              <vs-input disabled v-model="first_name"></vs-input>
            </vs-col>
            <vs-col type="flex" vs-w="6" v-else>
              <!-- <h6>{{ name }}</h6> -->
              <vs-input v-model="first_name"></vs-input>
            </vs-col>
            <vs-col type="flex" style="justify-content: center; display: flex" vs-w="2"
              v-if="this.update_first_name !== true">
              <vs-button color="primary" radius type="border" icon="create" @click="editFirstName()"></vs-button>
            </vs-col>
            <vs-col type="flex" style="justify-content: center; display: flex" vs-w="2"
              v-if="this.update_first_name === true">
              <vs-button color="primary" radiu type="border" icon="done_outline" @click="updateFirstName()"></vs-button>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Last Name</h6>
            </vs-col>
            <vs-col type="flex" vs-w="6" v-if="this.update_last_name !== true">
              <!-- <h6>{{ name }}</h6> -->
              <vs-input disabled v-model="last_name"></vs-input>
            </vs-col>
            <vs-col type="flex" vs-w="6" v-else>
              <!-- <h6>{{ name }}</h6> -->
              <vs-input v-model="last_name"></vs-input>
            </vs-col>
            <vs-col type="flex" style="justify-content: center; display: flex" vs-w="2"
              v-if="this.update_last_name !== true">
              <vs-button color="primary" radius type="border" icon="create" @click="editLastName()"></vs-button>
            </vs-col>
            <vs-col type="flex" style="justify-content: center; display: flex" vs-w="2"
              v-if="this.update_last_name === true">
              <vs-button color="primary" radiu type="border" icon="done_outline" @click="updateLastName()"></vs-button>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Email</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <h6>{{ email }}</h6>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Team</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="selectedTeam" :options="teams" style="z-index: 14000"></v-select>
              <p v-if="selectedTeam == 'SR'" style="color: #FF0000">Please assign Untapped, P3 and NEs to other CM spocs
                before changing the team to SR</p>
            </vs-col>
          </vs-row>

          <br />
          <!-- <vs-row>
            <vs-col type="flex" vs-w="4"> </vs-col>
            <vs-col type="flex" vs-w="2">
              <vs-radio v-model="highFlier" vs-value="HF">High Flier</vs-radio>
            </vs-col>
            <vs-col type="flex" vs-w="2">
              <vs-radio v-model="highFlier" vs-value="NHF">Non High Flier</vs-radio>
            </vs-col>
            <vs-col type="flex" vs-w="2">
              <vs-radio v-model="highFlier" vs-value="Consultant">Consultant</vs-radio>
            </vs-col>
          </vs-row> -->
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6> SPOC Level</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="selected_level" :options="levels" style="z-index: 13000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row
            v-if="this.selectedTeam === 'ExEd' || this.selectedTeam === 'CM' || this.selectedTeam === 'SR' || this.selectedTeam === 'GM' || this.selectedTeam === 'Futurense'">
            <vs-col type="flex" vs-w="4">
              <h6>Sub Team</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="selectedSubTeam" :options="subteams" style="z-index: 12000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>E Code</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <vs-input v-model="e_code"></vs-input>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Base City</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="base_city" :options="base_cities" style="z-index: 11000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Date Of Joning</h6>
            </vs-col>
            <vs-col type="flex" vs-w="4">
              <datepicker placeholder="Select Date" style="z-index:9000;" v-model="date_of_joining"></datepicker>
            </vs-col>
            <!-- <vs-col type="flex" vs-w="2">
              <vs-button color="primary" @click="editDateOfJoining()">Edit</vs-button>
            </vs-col> -->
          </vs-row>
          <!-- <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Date Of Joining</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <input type="date" id="birthday" name="date_of_joining" v-model="date_of_joining" style="width: 150px;height: 30px;">
            </vs-col>
          </vs-row> -->
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Week Off</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select multiple v-model="selectweekoff" :options="weekoffs" style="z-index: 8000"></v-select>
              <!-- <h6>{{ weekOff }}</h6> -->
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>City</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="city" :options="cities" style="z-index: 7000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row v-if="cities_sub_city_list[city]">
            <vs-col type="flex" vs-w="4">
              <h6>Sub City</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="selected_sub_city" :options="city_sub_city_list" style="z-index: 6000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>City Classification</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <v-select v-model="city_classification" :options="cityClassificationOptions"
                style="z-index: 5000"></v-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col type="flex" vs-w="4">
              <h6>Phone</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">
              <vs-input class="inputx" placeholder="Phone" v-model="phone" @input="restrictInput" />
            </vs-col>
          </vs-row>
          <br />
          <vs-row v-if="showcontact">
            <vs-col type="flex" vs-w="4">
              <h6>Contact View</h6>
            </vs-col>
            <vs-col type="flex" vs-w="8">

              <vs-checkbox v-model="contact_view">
              </vs-checkbox>
            </vs-col>
          </vs-row>
          <br>
          <vs-row>
            <vs-col type="flex" style="justify-content: center; display: flex" vs-w="12">
              <vs-button color="primary" @click="updateUser()">Submit</vs-button>
            </vs-col>
          </vs-row>
          <br />
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
// import EventBus from "../components/eventbus";
// import router from "@/router";
import moment from "moment";
export default {
  watch: {
    selectweekoff(val) {
      console.log("selectweekoff", val);
      let week_offs = this.$route.params.userdata.week_off.split(",");
      if (week_offs.length != 0 && val.length == 0) {
        this.selectweekoff = week_offs;
      }
    },
    city(val) {
      if (this.$route.params.userdata.city != val) {
        this.selected_sub_city = "";
      }
      this.city_sub_city_list = this.cities_sub_city_list[val];
    },
    selectedTeam: function (value) {
      if (value === "CM") {
        this.subteams = ["CM", "INT", "SHARK", "CFA", "IIML-FT"];
      } else if (value === "ExEd") {
        this.subteams = [
          "USP",
          "IIML-FT",
          "XLRI-HR",
          "XLRI-SH",
          "IIMI-BA",
          "IIMI-AA",
          "RPO"
        ];
      } else if (value === "SR") {
        this.subteams = [
          "SR",
          "Alumni SR",
          "Corporate Relations",
          "Collection",
          "Warehouse"
        ];
      } else if (value === "Futurense") {
        this.subteams = ["None", "DM"];
      } else if (value === "GM") {
        this.subteams = ["CM", "SR", "GM"];
      }
    }
  },
  data() {
    return {
      levels: [
        "T1",
        "T2",
        "T1-Con",
        "T2-Con",
        "Con",
        "L",
        "L1",
        "L2",
        "L3",
        "SR",
        "SL",
        "HF",
        "NHF",
        "SAB",
        "GM"
      ],
      selected_sub_city: "",
      city_sub_city_list: [],
      cities_sub_city_list: {
        // "Ahmedabad":["Ahmedabad"],
        Bangalore: ["Bangalore", "Mysore"],
        Chennai: ["Chennai", "Trichy"],
        // "Delhi":["Delhi"],
        // "Ernakulam":["Ernakulam"],
        // "Hyderabad":["Hyderabad"],
        Kolkata: ["Kolkata", "Bhuvneshwar", "Patna"],
        // "Mumbai": ["Mumbai"],
        Pune: ["Pune", "Indore", "Nagpur"]
        // "CFA": ["CFA"],
        // "Global": ["Global"],
        // "IIML-FT": ["IIML-FT"],
        // "IIML-SF": ["IIML-SF"],
        // "Trash": ["Trash"],
        // "YCity": ["YCity"],
      },
      selected_level: "",
      logged_in_user_id: "",
      showcontact: false,
      contact_view: false,
      update_first_name: false,
      update_last_name: false,
      first_name: "",
      last_name: "",
      teams: [],
      // highFlier: "",
      name: "",
      email: "",
      weekOff: "",
      selectedTeam: "",
      city: "",
      phone: "",
      user_id: "",
      cities: [],
      selectedSubTeam: "",
      selectweekoff: [],
      weekoffs: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      base_city: "",
      base_cities: [
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Hyderabad",
        "Kochi",
        "Kolkata",
        "Mumbai",
        "Managlore",
        "Pune",
        "Vijaywada",
        "Dubai"
      ],
      date_of_joining: null,
      e_code: "",
      city_classification: "",
      cityClassificationOptions: []
      // cityClassificationOptions: [
      //   "A",
      //   "B",
      //   "B1",
      //   "B2",
      //   "B3",
      //   "B4",
      //   "C",
      //   "D",
      //   "D1",
      //   "D2",
      //   "E",
      //   "G",
      //   "H",
      //   "H1",
      //   "H2",
      //   "K",
      //   "K1",
      //   "K2",
      //   "M1",
      //   "M2",
      //   "M3",
      //   "NJ",
      //   "P",
      //   "T1",
      //   "T2",
      //   "T3"
      // ],
    };
  },
  components: {
    "v-select": vSelect,
    Datepicker,
    moment
  },
  mounted() {
    this.getCityClasificationCodes();
    this.getUserdata();
    this.getSpocs();
    this.getTeams();

    if (this.selectedSubTeam === null) {
      this.selectedSubTeam = "";
    }
    this.logged_in_user_id = localStorage.getItem("user_id");
    if (this.logged_in_user_id == 39 || this.logged_in_user_id == 937) {
      this.showcontact = true;
    }

  },

  methods: {
    restrictInput() {
      this.phone = this.phone.replace(/[^0-9]/g, "");
    },
    editDateOfJoining() {
      console.log(this.date_of_joining, this.user_id);
      // if(this.date_of_joining != null){
      if (
        this.$route.params.userdata.date_of_joining !=
        moment(this.date_of_joining).format("DD-MMM-YYYY")
      ) {
        let obj = {
          user_id: this.user_id,
          date_of_joining: moment(this.date_of_joining).format("DD-MMM-YYYY")
        };
        axios
          .post(`${constants.SERVER_API}editDateOfJoining`, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            console.log(response);
            if (response.data.status == "success") {
              this.$vs.notify({
                text: response.data.message,
                color: "success"
              });
            }
            if (response.data.status == "error") {
              this.$vs.notify({
                text: response.data.message,
                color: "danger"
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          });
        // } else {
        //   this.$vs.notify({
        //     text: "Please enter Date of Joining",
        //     color: "danger",
        //   });
        // }
      }
    },
    editFirstName() {
      this.update_first_name = true;
    },
    editLastName() {
      this.update_last_name = true;
    },
    backtoUser() {
      this.$router.push("/UserManagement");
    },
    getUserdata() {
      console.log("user data route params---", this.$route.params.userdata);
      let userData = this.$route.params.userdata;

      this.first_name = userData.first_name;
      this.last_name = userData.last_name;
      this.name = userData.first_name;
      this.email = userData.email;
      if (userData.week_off != null) {
        this.selectweekoff = userData.week_off.split(",");
      }
      if (userData.contact_view == 1) {
        this.contact_view = true;
      }
      this.selectedTeam = userData.team;
      this.selectedSubTeam = userData.sub_team;
      this.city = userData.city;
      this.phone = userData.phone;
      this.user_id = userData.id;
      this.base_city = userData.base_city;
      if (userData.date_of_joining != null) {
        this.date_of_joining = moment(
          userData.date_of_joining,
          "DD-MMM-YYYY"
        ).format("YYYY-MM-DD");
      }
      this.e_code = userData.e_code;
      this.selected_level = userData.user_tag;
      this.city_classification = userData.city_classification;
      if (this.cities_sub_city_list[userData.city]) {
        this.selected_sub_city = userData.sub_city;
      }
    },
    updateFirstName() {
      let userdata = {
        user_id: this.user_id,
        first_name: this.first_name,
        last_name: this.last_name
      };
      console.log("userdata", userdata);
      let url = `${constants.SERVER_API}updateUserProfile`;
      axios
        .post(url, userdata, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.update_first_name = false;
          this.handleNotification(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    updateLastName() {
      let userdata = {
        user_id: this.user_id,
        first_name: this.first_name,
        last_name: this.last_name
      };
      console.log("userdata", userdata);
      let url = `${constants.SERVER_API}updateUserProfile`;
      axios
        .post(url, userdata, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.update_last_name = false;
          this.handleNotification(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    updateUser() {
      // console.log("date of joining ----",this.date_of_joining);
      // if (this.highFlier === undefined || this.highFlier === "") {
      //   this.highFlier = null;
      // }
      // let doj = this.date_of_joining
      // if(this.date_of_joining != null){
      //   doj = moment(this.date_of_joining, "YYYY-MM-DD").format("DD-MMM-YYYY")
      // }
      // console.log("doj ----",doj);
      if (this.date_of_joining == null) {
        this.$vs.notify({
          text: "Please enter Date of Joining",
          color: "danger"
        });
        return;
      }
      if (this.selectweekoff.length == 0) {
        this.$vs.notify({
          text: "Please select week off",
          color: "danger"
        });
        return;
      }
      this.editDateOfJoining();
      if (this.phone == "" || this.phone == null) {
        this.$vs.notify({
          text: "Please enter phone number",
          color: "danger"
        });
        return;
      }
      if (this.phone.length < 10 || this.phone.length > 10) {
        this.$vs.notify({
          text: "Phone number is not valid, Please enter 10 digit phone number",
          color: "danger"
        });
        return;
      }
      let userdata = {
        name: this.name,
        email: this.email,
        team: this.selectedTeam,
        week_off: this.selectweekoff.join(),
        sub_team: this.selectedSubTeam,
        city: this.city,
        sub_city: this.selected_sub_city,
        phone_number: this.phone,
        user_id: this.user_id,
        base_city: this.base_city,
        e_code: this.e_code,
        user_tag: this.selected_level,
        city_classification: this.city_classification,
        contact_view: this.contact_view
      };
      console.log("userdata", userdata);
      let url = `${constants.SERVER_API}UpdateUserInfo`;
      axios
        .post(url, userdata, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpocs() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.loggedin = response.data.logged_in_user_id;
          this.all_spocs = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map(name => {
          return {
            count: 1,
            name: name.city
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac1"
      ) {
        this.selected_city = this.cities[0];
      } else if (localStorage.getItem("role") === "cm1") {
        this.all_spocs.forEach(spoc => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
      //   this.selected_city = this.cities[0];
    },
    getTeams() {
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          let result = response.data.data;
          result.forEach(element => {
            this.teams.push(element.name);
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getCityClasificationCodes() {
      let url = `${constants.SERVER_API}getAllCityClasificationCodes`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("getCityClasificationCodes", response);
          for (let i = 0; i < response.data.length; i++) {
            const city_classification_code = response.data[i];
            if (city_classification_code != null) {
              this.cityClassificationOptions.push(city_classification_code);
            }
          }
          console.log(
            "cityClassificationOptions array",
            this.cityClassificationOptions
          );
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>
<style>
.con-select {
  width: 100%;
}

.vs-component.vs-con-input-label {
  width: 100%;
}

.datepicker .vdp-datepicker__calendar {
  z-index: 2000;
}
</style>
